import React, { useState, useEffect } from 'react';

const MusicPlayerScrubber = ({ audioRef, scaleFactor }) => {
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(1);

    useEffect(() => {
        const scrubber = document.querySelector('.Scrubber');
        if (scrubber) {
            const newBackgroundStyle = `linear-gradient(to right, var(--text-color) 0%, var(--text-color) ${(currentTime / duration) * 100}%, gray ${(currentTime / duration) * 100}%, gray 100%)`;
            scrubber.style.background = newBackgroundStyle;
        }
    }, [currentTime, duration]);

    useEffect(() => {
        const audioElement = audioRef.current;

        const updateTime = () => {
            setCurrentTime(audioElement.currentTime);
        };

        const setAudioData = () => {
            setDuration(audioElement.duration);
            setCurrentTime(audioElement.currentTime);
        };

        if (audioElement) {
            audioElement.addEventListener('timeupdate', updateTime);
            audioElement.addEventListener('loadeddata', setAudioData);
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('timeupdate', updateTime);
                audioElement.removeEventListener('loadeddata', setAudioData);
            }
        };
    }, [audioRef]);

    const handleScrubbing = (e) => {
        const audioElement = audioRef.current;
        const newTime = e.target.value / scaleFactor;
        if (audioElement) {
            audioElement.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    return (
        <input 
            type="range"
            className="Scrubber"
            min="0"
            max={duration * scaleFactor}
            value={currentTime * scaleFactor}
            onChange={handleScrubbing}
        />
    );
};

export default MusicPlayerScrubber;
