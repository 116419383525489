import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import Topbar from './Topbar';
import { useAuth } from './AuthContext';
import CancelCard from './CancelCard';
import SubscribeCard from './SubscribeCard';

import '../styles/AuthForm.css';
import '../styles/UserManagement.css';

const UserManagement = () => {
    const { isLoggedIn, setIsLoggedIn, setNotification } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [authDict, setAuthDict] = useState({
        google: false,
        spotify: false,
        local: false,
        email: '',
        any: false,
        is_subscribed: false,
    });


    useEffect(() => {
        if (!isLoggedIn) { 
            navigate('/login');
        }
    }, [location, isLoggedIn]);

    useEffect(() => {
        const fetchUserData = async () => {
            const response = await fetch('/api/auth/user', {
                method: 'GET',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setAuthDict(data);
            } else {
                // Frontend not in sync with backend, remove auth cookie
                setIsLoggedIn(false);
                Cookies.remove('auth');
            }
        };

        fetchUserData();
    }, []);

    const connectDisconnectSpotify = async () => {
        if (authDict.spotify) {
            // Disconnect the Spotify account
            const response = await fetch('/api/auth/spotify/unlink', {
                method: 'POST',
                credentials: 'include',
            });
    
            if (response.ok) {
                const data = await response.json();
                setAuthDict({
                    ...authDict,
                    spotify: false,
                });
                setNotification({ message: data.message, type: 'success' });
            } else {
                const data = await response.json();
                setNotification({ message: data.error, type: 'error' });
            }
        } else {
            // Connect the Spotify account
            window.open('/api/auth/spotify', '_blank');
        }
    };

    const deleteAccount = async () => {
        const confirmation = window.prompt("Are you sure about this? This action is irreversible. Please enter 'Delete account' to confirm:");
        
        if (confirmation === null || confirmation !== 'Delete account') {
            // User pressed cancel
            return;
        }
    
        const response = await fetch('/api/auth/user/delete', {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    
        const data = await response.json();
    
        if (response.ok) {
            setIsLoggedIn(false);
            Cookies.remove('auth');
            navigate('/');
            setNotification({ message: 'Account deleted successfully', type: 'success' });
        } else if (response.status === 500) {
            setNotification({ message: 'Failed to delete account.', type: 'error' });
        } else {
            setNotification({ message: data.error || 'An unexpected error occurred.', type: 'error' });
        }
    };
    

    return (
        <>
            <Topbar showSearch={false} />
            <div className="UserManagement">
                <h1>User Management</h1>
                { process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY && !authDict.is_subscribed ? <SubscribeCard /> : null}
                <input 
                    type="email" 
                    value={authDict.email || 'No linked email address'} 
                    disabled 
                    className="disabledInput wideInput"
                />
                <div className="OAuthButtons">
                    <a className="OAuthButton spotify" onClick={connectDisconnectSpotify}>
                        <img src={`${process.env.PUBLIC_URL}/spotify_logo.svg`} alt="Spotify Icon" />
                        {(!authDict.spotify ? "Connect" : "Disconnect") + " Spotify Account"} 
                    </a>
                    <a className="OAuthButton logoutButton mt-3" onClick={() => navigate('/logout')}>Log Out</a>
                    <div className="separator"></div>
                    {process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY && authDict.is_subscribed ? <CancelCard cancel={() => setAuthDict({ ...authDict, is_subscribed: false })} /> : null}
                    <button className="OAuthButton styledButton" onClick={deleteAccount}>Delete Account and Data</button>
                </div>
            </div>
        </>
    );
};


export default UserManagement;
