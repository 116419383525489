import React, { createContext, useContext, useState } from 'react';

const SearchbarFocusContext = createContext();

export const useSearchFocus = () => {
    const context = useContext(SearchbarFocusContext);
    if (!context) {
        throw new Error("useSearchFocus must be used within a SearchFocusProvider");
    }
    return context;
};

export const SearchFocusProvider = ({ children }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isMouseDownOnResults, setIsMouseDownOnResults] = useState(false);
    return (
        <SearchbarFocusContext.Provider value={{ isFocused, setIsFocused, isMouseDownOnResults, setIsMouseDownOnResults}} >
            {children}
        </SearchbarFocusContext.Provider>
    );
};
