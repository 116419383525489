import React, { forwardRef, useRef } from 'react';

import { setPlaceholderOnError } from '../utils/utilMethods';
import TopbarSearchbarResultsRow from './TopbarSearchbarResultsRow';

const TopbarSearchbarResults = forwardRef(({ searchResults, setIsFocused }, ref) => {
    const N = 5;
    const ITEM_HEIGHT = 56;

    const itemRef = useRef();

    return (
        <div 
            ref={ref}
            className="SearchbarResults position-absolute"
            style={{ 
                top: '100%',
                maxHeight: `${N * ITEM_HEIGHT}px`,
                overflowY: 'auto'
            }}
        >
            {searchResults.map(result => (
                <TopbarSearchbarResultsRow ref={itemRef} result={result} setPlaceholderOnError={setPlaceholderOnError} />
            ))}
        </div>
    );
});

export default TopbarSearchbarResults;