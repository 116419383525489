import SongRow from './SongRow'
import SongSimilarSettings from './SongSimilarSettings';
import SkeletonRow from '../skeletons/SkeletonRow';
import { useEffect } from 'react';

import { useMixing } from './MixingContext';

function SongSimilar({ songs, loadMore, isLoading, setParams }) {
    const { shouldMix } = useMixing();

    useEffect(() => {
        window.addEventListener('scroll', loadMore);
        return () =>{
            window.removeEventListener('scroll', loadMore);
        };
    }, [loadMore]);

    const h2ExtraText = shouldMix ? '(playlist-guided)' : ''

    return (
        <div className="SongSimilar">
            <div className="SongRows">
                <div className="SongSimilarHeader">
                    <div className="SongSimilarHeaderText">
                        <h2 style={{whiteSpace: "nowrap"}}>Similar Tracks</h2>
                        <span className="playlist-guided-text">{h2ExtraText}</span>
                    </div>
                    <SongSimilarSettings setParams={setParams} />
                </div>
                {songs.map(song => (
                    <SongRow key={`similar_${song.id}`} song={song} />
                ))}
                {isLoading && 
                    [...Array(20)].map((_, i) => <SkeletonRow key={i} />) 
                }
            </div>
        </div>
    )
}


export default SongSimilar
