import Topbar from './Topbar';
import SongRow from './SongRow';
import Searchbar from './TopbarSearchbar';
import { useAudio } from './AudioProvider';
import { dedupeSongs } from '../utils/utilMethods';
import { useAuth } from './AuthContext';

import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import '../styles/LandingPage.css';

const LandingPage = () => {
    const { setSongList } = useAudio();

    const [showcaseSongs, setShowcaseSongs] = useState([]);

    const title = "Vibesim";

    useEffect(() => {
        document.title = title;
    }, []);

    useEffect(() => {
        fetch(`/api/showcase`)
            .then(response => response.json())
            .then(data => {
                const dedupedData = dedupeSongs(data);
                setShowcaseSongs(dedupedData);
                setSongList(dedupedData);
            })
            .catch(error => {
                console.error('Error fetching example songs:', error);
            });
    }, []);

    return (
        <>
        <Topbar showSearch={false} />
        <div className="LandingPage">
            <div className="LandingPageHeaders">
                <h1>Search for similar vibes.</h1>
                <div className="LandingPageSubtitle">Get song recommendations with similar vibes.</div>
            </div>
            <Searchbar />
            <div className="ShowcaseRows">
                {showcaseSongs.map((song, i) => (
                    <SongRow key={song.id} song={song} showDownload={false} loadFirst={i === 0} />
                ))}
            </div>
        </div>
        </>
    );
};

export default LandingPage;
