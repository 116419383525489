import { useAudio } from "./AudioProvider";

function SpotifyButton({ song }) {
    const { audioRef, isPlaying, setIsPlaying } = useAudio();

    const handleSpotifyClick = (e) => {
        e.stopPropagation();
    
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    
        let fallbackToWebPlayer;
        const spotifyUri = `spotify:track:${song.id}`;
        const spotifyWebUrl = `https://open.spotify.com/track/${song.id}`;
    
        window.open(spotifyUri, '_blank');
    
        fallbackToWebPlayer = setTimeout(() => {
            window.open(spotifyWebUrl, '_blank');
        }, 500);
    
        // Clear the fallback if the tab loses focus, assuming Spotify app took over
        window.addEventListener('blur', () => {
            clearTimeout(fallbackToWebPlayer);
        });
    };
    

    return (
        <button className="ext-button" onClick={handleSpotifyClick}>
            <img
                className="spotify-icon"
                src={`${process.env.PUBLIC_URL}/spotify_green.png`}
                alt="Listen on Spotify"
                draggable="false"
            />
        </button>
    )
}

export default SpotifyButton;
