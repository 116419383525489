import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SimilarButton = ({ onClick }) => (
    <button className="similar-button" onClick={onClick}>
        <FontAwesomeIcon icon={faSearch} className="similar-icon" />
        <span className="similar-text">&nbsp; Similar</span>
    </button>
);

export default SimilarButton;