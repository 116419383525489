import { setPlaceholderOnError } from "../utils/utilMethods";

function SongCover({ cover, isPlaying }) {
    return (
        <div className="SongCover d-flex justify-content-center align-items-center">
            <img src={cover} alt="cover" className={isPlaying ? 'playing' : ''} onError={setPlaceholderOnError} />
        </div>
    );
}

export default SongCover;