import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../styles/Menu.css';
import ThemeToggle from './ThemeToggle';
import { useAuth } from './AuthContext';

const Menu = ({ isOpen, closeMenu }) => {
    const menuRef = useRef(null);
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target.closest('.MenuIcon')) {
                return;
            }
            else if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        }

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [closeMenu]);

    const userOrLogin = isLoggedIn ? (
        <>
            <Link to="/user" onClick={closeMenu} className="Menu-link">Manage Account</Link>
            <ThemeToggle asButton />
        </>
    ) : (
        <>
            <Link to="/login" onClick={closeMenu} className="Menu-link">Log In</Link>
            <Link to="/register" onClick={closeMenu} className="Menu-link">Register</Link>
        </>
    );

    return (
        <>
            <div className={`Overlay ${isOpen ? 'open' : ''}`} onClick={closeMenu}></div>
            <div ref={menuRef} className={`Menu ${isOpen ? 'open' : ''}`}>
                <div className="Menu-top-section">
                    {userOrLogin}
                </div>
                <div className="separator"></div>
                <div className="Menu-middle-section">{isLoggedIn ? (
                    <Link to="/logout" className="Menu-link">Log Out</Link>
                ) : <ThemeToggle asButton />}</div>
            </div>
        </>
    );
};

export default Menu;
