import "../styles/MusicPlayer.css"

import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle, faVolumeUp, faVolumeMute, faVolumeOff, faVolumeDown } from '@fortawesome/free-solid-svg-icons';

import { useAudio } from './AudioProvider';
import MixingContainer from "./MixingContainer";
import MusicPlayerScrubber from "./MusicPlayerScrubber";


const MusicPlayer = () => {
    const location = useLocation();
    const excludedRoutes = ['/login', '/register', '/user'];
    const { isPlaying, setIsPlaying, audioRef } = useAudio();
    const [isMuted, setIsMuted] = useState(false);
    const [volume, setVolume] = useState(Cookies.get('volume') || 100);
    const scaleFactor = 100;

    useEffect(() => {
        handleVolumeChange({ target: { value: volume } });
    }, []);

    useEffect(() => {
        Cookies.set('volume', volume);
    }, [volume]);

    useEffect(() => {
        const volumeSlider = document.querySelector('.VolumeSlider');
        if (volumeSlider) {
            const effectiveVolume = isMuted ? 0 : volume;
            const newBackgroundStyle = `linear-gradient(to right, var(--accent) 0%, var(--accent) ${effectiveVolume}%, var(--primary) ${effectiveVolume}%, var(--primary) 100%)`;
            volumeSlider.style.background = newBackgroundStyle;
        }
    }, [volume, isMuted]);

    const getVolumeIcon = () => {
        if (isMuted || volume === 0) return faVolumeMute;
        if (volume <= 33) return faVolumeOff;
        if (volume <= 66) return faVolumeDown;
        return faVolumeUp;
    };

    const handlePlayPause = () => {
        const audioElement = audioRef.current;
        if (audioElement) {
            if (isPlaying) {
                audioElement.pause();
                setIsPlaying(false);
            } else {
                audioElement.play();
                setIsPlaying(true);
            }
        }
    };

    const handleVolumeChange = (e) => {
        const audioElement = audioRef.current;
        const newVolume = e.target.value;
        if (isMuted && newVolume > 0) setIsMuted(false);
        if (audioElement) {
            audioElement.volume = newVolume / scaleFactor;
            setVolume(newVolume);
        }
    };

    const toggleMute = () => {
        const audioElement = audioRef.current;
        if (audioElement) {
            if (isMuted) {
                audioElement.volume = volume / scaleFactor;
                setIsMuted(false);
            } else {
                audioElement.volume = 0;
                setIsMuted(true);
            }
        }
    };

    return (
        <div className={"MusicPlayerContainer" + (excludedRoutes.includes(location.pathname) ? " hidden" : "")}>
            <MixingContainer />
            <div className="MusicPlayer">
                <div className="ControlsContainer">
                    <FontAwesomeIcon 
                        icon={isPlaying ? faPauseCircle : faPlayCircle}
                        size="2x"
                        className="PlayPauseButton pointer" 
                        onClick={handlePlayPause}
                    />
                </div>
                <MusicPlayerScrubber audioRef={audioRef} scaleFactor={scaleFactor} />
                <div className="VolumeContainer">
                    <div className="VolumeIconContainer">
                        <FontAwesomeIcon 
                            icon={getVolumeIcon()}
                            size="1x"
                            className="pointer"
                            onClick={toggleMute}
                        />
                    </div>
                    <input 
                        type="range" 
                        className="VolumeSlider"
                        min="0" 
                        max="100" 
                        value={isMuted ? 0 : volume}
                        onChange={handleVolumeChange}
                    />
                </div>
            </div>
        </div>
    );
    
};

export default MusicPlayer;