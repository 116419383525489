import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useStripePromise } from './StripeContext';
import '../styles/SubscribeCard.css';

const SubscribeCard = () => {
    const stripePromise = useStripePromise();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSubscribe = async () => {
        setIsLoading(true);
        const response = await fetch("/api/stripe", {
            method: "POST",
        });

        if (!response.ok) {
            setIsLoading(false);
            setIsError(true);
            return;
        }

        const session = await response.json();

        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
    };

    const check = <FontAwesomeIcon icon={faCheck} />;
    let buttonText = isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "$4.99 per month";
    buttonText = isError ? "Error with Stripe" : buttonText;

    return (
        <div className="SubscribeCard">
            <h2>Subscribe</h2>
            <div className="SubscribeCardContent">
                <p>{check} &nbsp;More <b>filtering</b> options</p>
                <p>{check} &nbsp;<b>Exporting playlists to Spotify</b></p>
                <p>{check} &nbsp;Priority access to <b>new features</b></p>
            </div>
            <button className={"OAuthButton" + (isError ? " ButtonError" : "")} onClick={handleSubscribe}>{buttonText}</button>
        </div>
    );
};

export default SubscribeCard;
