
import SpotifyButton from './SpotifyButton';
import SimilarButton from './SimilarButton';
import { useAudio } from './AudioProvider';
import { useMixing } from './MixingContext';
import { areWePlaying } from '../utils/areWePlaying';
import Equalizer from './Equalizer';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function SongRow({ song, showDownload=true, loadFirst=false, inMixingList=false }) {
    let navigate = useNavigate();
    const { playingSong, isPlaying, setPlayingSong, audioRef, handlePlayPause, usingMixingList, setUsingMixingList } = useAudio();
    const { setIsExpanded, mixingList, addSong, removeSong, maxLength } = useMixing();

    const isSongInList = mixingList.some(existingSong => existingSong.id === song.id);
    const weArePlaying = isPlaying && areWePlaying(song, playingSong);

    const maxLengthReached = mixingList.length >= maxLength;

    const handleSongClick = (e) => {
        e.stopPropagation();
        navigate(`/song/${song.id}`);
    };

    const handleSongPlayPause = (e) => {
        e.stopPropagation();
        if (inMixingList && (!song || !playingSong || song.id !== playingSong.id)) {
            setUsingMixingList(true);
        } else {
            setUsingMixingList(false);
        }
        handlePlayPause(song);
    };

    const handleAddSongClick = (e) => {
        e.stopPropagation();
        if (maxLengthReached && !isSongInList) return;
        if (isSongInList) {
            removeSong(song.id);
        } else {
            addSong(song);
            setIsExpanded(true);
        }
    }

    useEffect(() => {
        const audioElement = audioRef.current;
        if (audioElement && loadFirst && audioElement.src == "") {
            audioElement.src = song.preview_url;
            setPlayingSong(song);
        }
    }, []);

    const truncateClass = showDownload ? 'truncate-row' : 'truncate-row-single';
    const button = <SpotifyButton song={song} />;

    const randomSuffix = Math.floor(Math.random() * 1000000);
    const suffix = `${song.id}-${randomSuffix}`

    const tooltipDataAttributes = {
        'data-tooltip-id': `tooltip-${suffix}`,
        'data-tooltip-content': isSongInList ? "Remove from playlist" : "Add to playlist",
        'data-tooltip-place': 'top',
    };

    const buttonStyle = (maxLengthReached && !isSongInList) ? { pointerEvents: 'none' } : {};
    let rowStyle = `SongRow`
    rowStyle += inMixingList ? ' inMixingList' : '';
    rowStyle += weArePlaying ? ' playing' : '';
    rowStyle += maxLengthReached && !isSongInList ? '' : ' pointer';

    return (
        <div className={rowStyle} onClick={handleSongPlayPause}>
            {!inMixingList && <Tooltip className="tooltip" id={`tooltip-${suffix}`} />}
            <div style={buttonStyle} className="add-button" id={`${suffix}`} onClick={handleAddSongClick} {...tooltipDataAttributes}>
                {weArePlaying ? (
                    <Equalizer />
                ) : (isSongInList ? '-' : '+')}
            </div>
            <div className="SongRowBody">
                <div className="flexrow">
                    <div className="SongRowInfo">
                        <h4 className={truncateClass}>{song.title}</h4>
                        <p className={truncateClass}>{song.artist}</p>
                    </div>
                </div>
                <div className="flexrow">
                    {showDownload && button}
                    <SimilarButton onClick={handleSongClick} />
                </div>
            </div>
        </div>
    )
}

export default SongRow;