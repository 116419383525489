import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tooltip/dist/react-tooltip.css'

import LandingPage from './components/LandingPage';
import Song from './components/Song';
import { AuthProvider } from './components/AuthContext';
import { ThemeProvider } from './components/ThemeContext';
import { AudioProvider } from './components/AudioProvider';
import { MixingProvider } from './components/MixingContext';
import { StripeContext } from './components/StripeContext';
import { SearchFocusProvider } from './components/SearchFocusProvider';
import MusicPlayer from './components/MusicPlayer';
import CookieConsent from './components/CookieConsent';
import AuthForm from './components/AuthForm';
import UserManagement from './components/UserManagement';

import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const loadAnalyticsScript = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-FE7C7MV16Y";
  script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() { window.dataLayer.push(arguments); };
      window.gtag('js', new Date());
      window.gtag('config', 'G-FE7C7MV16Y', { 'anonymize_ip': true });
  };
  document.head.appendChild(script);
};

const Analytics = ({ hasConsent }) => {
  const location = useLocation();

  useEffect(() => {
      if (hasConsent && typeof window.gtag === 'function') {
          window.gtag('event', 'page_view', {
              'page_path': location.pathname,
          });
      }
  }, [location, hasConsent]);

  return null;
};

const App = () => {
  const [hasConsent, setHasConsent] = useState(Cookies.get('hasConsent') === true);

  useEffect(() => {
      document.title = "Vibesim";
  }, []);

  useEffect(() => {
      const root = document.documentElement;
      root.style.setProperty('--accent', '#1DB954');
      root.style.setProperty('--accent-hover', '#1aa34a');
  }, []);

  const handleCookieDecision = (decision) => {
    if (decision === hasConsent) return;
    setHasConsent(decision);
    Cookies.set('hasConsent', decision, { expires: 180 });
    loadAnalyticsScript();
  };

  return (
      <div className="app flex-container">
          <Router>
            <AuthProvider>
              <Analytics hasConsent={hasConsent} />
              <SearchFocusProvider>
                  <MixingProvider>
                      <AudioProvider>
                        <ThemeProvider>
                          <StripeContext.Provider value={stripePromise}>
                          <Routes>
                              <Route path="/" element={<LandingPage />} />
                              <Route path="/song/:id" element={<Song />} />
                              <Route path="/login" element={<AuthForm mode="login"/>} />
                              <Route path="/register" element={<AuthForm mode="register"/>} />
                              <Route path="/logout" element={<AuthForm mode="logout"/>} />
                              <Route path="/user" element={<UserManagement />} />
                              <Route path="*" element={<Navigate to="/" replace />} />
                          </Routes>
                          </StripeContext.Provider>
                          <MusicPlayer />
                          <CookieConsent handleDecision={handleCookieDecision} />
                        </ThemeProvider>
                      </AudioProvider>
                  </MixingProvider>
              </SearchFocusProvider>
            </AuthProvider>
          </Router>
      </div>
  );
};

export default App;
