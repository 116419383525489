import { useEffect } from "react";

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import '../styles/CookieConsent.css';

const pluginConfig = {
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false
  
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0


    languages: {
      en: {
        consent_modal: {
          title: 'Cookies',
          description:
            'We use cookies for basic functionalities of the website and to understand how the website is being used. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all',
          },
          secondary_btn: {
            text: 'Reject all',
            role: 'accept_necessary',
          },
        },
        settings_modal: {
          title: 'Cookie Settings',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
            { col4: 'Description' },
          ],
          blocks: [
            {
              title: 'Cookie usage',
              description:
                'We use cookies for basic functionalities of the website and to understand how the website is being used. We use no marketing cookies and no cookies for advertisement purposes.',
            },
            {
              title: 'Strictly necessary cookies',
              description:
                'These cookies are essential for the proper functioning of the website. Without these cookies, the website would not work properly',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true,
              },
            },
            {
              title: 'Analytics cookies',
              description:
                'We use these cookies to measure how the website is being used. All information collected by these cookies is aggregated and therefore anonymous.',
              toggle: {
                value: 'analytics',
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                {
                  col1: '^_ga',
                  col2: 'google.com',
                  col3: '2 years',
                  col4: 'Google Analytics',
                  is_regex: true,
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '1 day',
                  col4: 'Google Analytics',
                },
              ],
            },
            // {
            //   title: 'Advertisement and Targeting cookies',
            //   description:
            //     'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
            //   toggle: {
            //     value: 'targeting',
            //     enabled: false,
            //     readonly: false,
            //   },
            // },
            // {
            //   title: 'More information',
            //   description:
            //     'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
            // },
          ],
        },
      },
    },
    gui_options: {
        consent_modal: {
            layout: 'bar',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            position: 'left',              // left/right
            transition: 'slide'            // zoom/slide
        }
    },
  };
  

export default function CookieConsent({ handleDecision }) {
    useEffect(() => {
        if (!document.getElementById('cc--main')) {
            window.CC = window.initCookieConsent();
            window.CC.run({
                ...pluginConfig,
                onAccept: (cookies) => {
                    handleDecision(cookies.categories.includes('analytics'));
                },
                onChange: () => {
                    const analyticsEnabled = window.CC.allowedCategory('analytics');
                    handleDecision(analyticsEnabled);
                },
                onFirstAction: () => {
                    const analyticsEnabled = window.CC.allowedCategory('analytics');
                    handleDecision(analyticsEnabled);
                },
            });
        }
    }, []);

    return null;
}
