import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import '../styles/SubscribeCard.css';

const CancelCard = ({ cancel }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleCancel = async () => {
        setIsLoading(true);
        const response = await fetch("/api/stripe/cancel", {
            method: "POST",
        });

        if (!response.ok) {
            setIsLoading(false);
            setIsError(true);
            return;
        }

        setIsLoading(false);
        cancel();
    };

    let buttonText = isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Cancel Subscription";
    buttonText = isError ? "Error with Stripe" : buttonText;

    return (
         <div className="CancelCard">
            {/* <h2>Cancel Subscription</h2>
            <div className="SubscribeCardContent">
                <p>Are you sure you want to cancel your subscription?</p>
            </div> */}
        <button className={"OAuthButton mb-3 styledButton" + (isError ? " ButtonError" : "")} onClick={handleCancel}>{buttonText}</button>
        </div>
    );
};

export default CancelCard;
