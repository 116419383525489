import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';
import { useAudio } from './AudioProvider';
import { areWePlaying } from '../utils/areWePlaying';

import { useEffect } from 'react';

function PlayPauseButton({ song, size, loadFirst=false }) {
    const { playingSong, isPlaying, setIsPlaying, audioRef, handlePlayPause, setPlayingSong } = useAudio();
    const previewUrl = song.preview_url;
    const weArePlaying = isPlaying && areWePlaying(song, playingSong);

    useEffect(() => {
        const audioElement = audioRef.current;
        if (audioElement) {
            if (weArePlaying) {
                audioElement.play();
                setIsPlaying(true);
            }
            else if (loadFirst && audioElement.src == "") {
                audioElement.src = previewUrl;
                setPlayingSong(song);
            }
        }
    }, []);

    return (
        <>
            <FontAwesomeIcon 
                icon={weArePlaying ? faPauseCircle : faPlayCircle}
                size={size} 
                className="PlayPauseButton pointer" 
                onClick={() => handlePlayPause(song)}
            />
        </>
    );
}

export default PlayPauseButton;
