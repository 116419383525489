import React, { useState, useEffect, useRef } from 'react';
import SongRow from './SongRow';
import { useMixing } from './MixingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';


const MixingContainer = () => {
    const containerRef = useRef(null);
    const [calculatedHeight, setCalculatedHeight] = useState('auto');
    const { isExpanded, setIsExpanded, mixingList, shouldMix, setShouldMix, maxLength } = useMixing();

    useEffect(() => {
        if (containerRef.current && isExpanded) {
            setCalculatedHeight(`${containerRef.current.scrollHeight}px`);
        }
    }, [mixingList, containerRef, isExpanded]);


    const disableButtons = mixingList.length === 0;

    const containerClass = (isExpanded && !disableButtons) ? 'MixingList' : 'MixingList collapsed';
    const iconClass = (isExpanded && !disableButtons) ? '' : 'rotate-chevron';

    const containerStyle = { maxHeight: isExpanded ? calculatedHeight : '0' };

    const playlistTooltip = disableButtons ? 'Add songs before enabling playlist-guided suggestions' : 'Playlist-guided suggestions';

    return (
        <div className="MixingContainer">
            <Tooltip className="tooltip" anchorSelect=".mixing-toggle" place="top">{playlistTooltip}</Tooltip>
            <div className="MixingHeader">
                <button className="expand-btn" onClick={() => setIsExpanded(!isExpanded)} disabled={disableButtons}>
                    <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${iconClass}`} />
                </button>
                <h5 className={`list-count ${!shouldMix && (!isExpanded || disableButtons) ? 'hidden' : ''}`}>
                    {mixingList.length}/{maxLength}
                </h5>
                <Form.Check
                    className="setting-switch mixing-toggle"
                    type="switch"
                    label=""
                    checked={shouldMix}
                    onChange={() => setShouldMix(!shouldMix)}
                    disabled={disableButtons}
                />
            </div>
            <div className={containerClass} style={containerStyle} ref={containerRef}>
                {mixingList.map((song, index) => (
                    <SongRow key={index} song={song} inMixingList={true} />
                ))}
            </div>
        </div>
    );
};

export default MixingContainer;
