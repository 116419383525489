import React, { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import Notification from './Notification';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

const checkLoginStatus = () => {
    const cookieStr = Cookies.get('auth');
    const cookie = cookieStr ? JSON.parse(cookieStr) : null;
    return cookie && cookie.any;
};

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(checkLoginStatus());
    const [notification, setNotification] = useState({ message: '', type: '' });
    const notificationMap = {
        'success_': { message: 'Logged in successfully', type: 'success' },
        'success_user': { message: 'Successfully linked account', type: 'success' },
        'error_': { message: 'Unable to log in', type: 'error' },
        'error_user': { message: "Unable to link account, make sure the account you're trying to link is not already linked", type: 'error' },
        'unauthorized_': { message: 'You must be logged in to access this page', type: 'error' },
    };
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const key = queryParams.get('auth') + '_' + queryParams.get('source');
        const source = queryParams.get('source') || '';
        if (notificationMap[key]) {
            setNotification(notificationMap[key]);
            navigate('/' + source);
        }
    }, [location, setNotification]);

    const closeNotification = () => {
        setNotification({ message: '', type: '' });
    };

    useEffect(() => {
        setIsLoggedIn(checkLoginStatus());
    }, []);

    const value = {
        isLoggedIn,
        setIsLoggedIn,
        notification,
        setNotification,
        closeNotification
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
            <Notification message={notification.message} type={notification.type} closeNotification={closeNotification} />
        </AuthContext.Provider>
    );
};
