import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import Topbar from './Topbar';
import { useAuth } from './AuthContext';
import { useTheme } from './ThemeContext';

import '../styles/AuthForm.css';

const logoutUser = async (setIsLoggedIn = () => {}) => {
    const response = await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    
    if (response.ok) {
        setIsLoggedIn(false);
        Cookies.remove('auth');
    }
};

const AuthForm = ({ mode }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const recaptchaRef = React.createRef();
    const { setIsLoggedIn, setNotification } = useAuth();

    const { isDarkMode } = useTheme();

    const navigate = useNavigate();

    useEffect(() => {
        if (mode === 'logout') {
            logoutUser(setIsLoggedIn);
            navigate('/');
            setNotification({ message: 'Logged out successfully', type: 'success' });
        }
    }, [mode]);

    useEffect(() => {
        const cookieStr = Cookies.get('auth');
        const cookie = cookieStr ? JSON.parse(cookieStr) : null;
        if (cookie && cookie.any){ 
            navigate('/');
        }
    }, []);

    const isRegisterMode = mode === 'register';
    const apiEndpoint = isRegisterMode ? 'api/auth/local/register' : 'api/auth/local/login';
    const headerText = isRegisterMode ? 'Create your account' : 'Welcome back!';
    const buttonText = isRegisterMode ? 'Register' : 'Log In';
    const oppositeMode = isRegisterMode ? 'log in' : 'register';
    const oppositeText = isRegisterMode ? 'Already have an account?' : 'Don\'t have an account?';

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const recaptcha = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, recaptcha }),
            });
            const data = await response.json();
            
            if (response.ok) {
                setIsLoggedIn(true);
                navigate('/');
                setNotification({ message: 'Logged in successfully', type: 'success' });
            } else {
                setNotification({ message: data.error, type: 'error' });
            }
        } catch (error) {
            setNotification({ message: error.message, type: 'error' });
        }
    };

    return (
        <>
            <Topbar showSearch={false} />
            <div className="AuthForm">
                <h1>{headerText}</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">{buttonText}</button>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        size="invisible"
                        theme={isDarkMode ? 'dark' : 'light'}
                    />
                </form>
                <div className="opposite">
                    <span>{oppositeText} <Link to={`/${oppositeMode}`}>{oppositeMode}</Link></span>
                </div>
                <div className="separator">
                </div>
                <div className="OAuthButtons">
                    <a className="OAuthButton google" href="api/auth/google">
                        <img src={`${process.env.PUBLIC_URL}/google_logo.svg`} alt="Google Icon" />
                        Continue with Google
                    </a>
                    <a className="OAuthButton spotify" href="api/auth/spotify">
                        <img src={`${process.env.PUBLIC_URL}/spotify_logo.svg`} alt="Spotify Icon" />
                        Continue with Spotify
                    </a>
                </div>
            </div>
        </>
    );
};

export default AuthForm;
