import '../styles/Song.css';

import SongCard from './SongCard';
import SongSimilar from './SongSimilar';
import { useAudio } from './AudioProvider'; 
import { useMixing } from './MixingContext';
import { dummySong } from '../utils/dummyData.js'; 
import { dedupeSongs } from '../utils/utilMethods';
import Topbar from './Topbar';

import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import SkeletonCard from '../skeletons/SkeletonCard';

function Song() {
    const { id } = useParams();
    const { setSongList } = useAudio();
    const [song, setSong] = useState(dummySong);
    const [similarSongs, setSimilarSongs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSimilar, setIsLoadingSimilar] = useState(true);
    const [page, setPage] = useState(1);
    const [params, setParams] = useState({});
    const [fetchedParams, setFetchedParams] = useState(new URLSearchParams());
    const [fetchedId, setFetchedId] = useState(null);
    const [timeoutId, setTimeoutId] = useState(null);

    const { mixingList, shouldMix } = useMixing();

    const title = song.title ? `${song.title} by ${song.artist} | Vibesim` : 'Vibesim';

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        setPage(1);
    }, [id, shouldMix, params])

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'instant' });
        setIsLoading(true);
        fetch(`/api/search/${id}`)
            .then(response => response.json())
            .then(data => {
                setSong(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(`Error fetching beatmapset with id: ${id}`, error);
            });
    }, [id])

    useEffect(() => {
        if (shouldMix) setPage(1);
    }, [mixingList])

    useEffect(() => {
        let endpoint = shouldMix ? `/api/similarities/mix` : `/api/similarities/${id}`;
        let urlParams = createParams();
        if (!Object.keys(params).length) return;
        const urlParamsChanged = urlParams.toString() !== fetchedParams.toString();
        if (!similarSongs.length || urlParamsChanged || fetchedId !== id) {
            const flush = shouldFlush(fetchedParams, urlParams);
            if (flush) setSimilarSongs([]);
            setIsLoadingSimilar(true);
            let timeoutTime = 800;
        
            if (timeoutId) clearTimeout(timeoutId);
            const newTimeoutId = setTimeout(() => {
                fetch(`${endpoint}?${urlParams.toString()}`)
                    .then(response => response.json())
                    .then(data => {
                        const dedupedData = flush ? dedupeSongs(data) : dedupeSongs([...similarSongs, ...data]);
                        setSimilarSongs(dedupedData);
                        setIsLoadingSimilar(false);
                        setFetchedParams(urlParams);
                        setFetchedId(id);
                    })
                    .catch(error => {
                        console.error(`Error fetching similar songs with id: ${id}`, error);
                    });
            }, timeoutTime);
            setTimeoutId(newTimeoutId);
        }
    }, [id, page, params, shouldMix, mixingList]);

    const createParams = () => {
        let effectivePage = fetchedId !== id ? 1 : page;

        let urlParams = new URLSearchParams({
            ...params,
            page: effectivePage,
        });
    
        if (shouldMix) {
            const mixingIds = mixingList.map(song => song.id).join(',');
            urlParams.set('ids', id + ',' + mixingIds);
        }

        return urlParams;
    }

    const shouldFlush = (fetchedParams, newParams) => {
        if (fetchedId !== id) return true;
        if (newParams.get('ids') !== fetchedParams.get('ids')) return true;
        if (newParams.get('remove_same_artist') !== fetchedParams.get('remove_same_artist')) return true;
    }

    useEffect(() => {
        if (similarSongs.length) {
            setSongList(similarSongs);
            handleScroll();
        }
    }, [similarSongs]);

    let debounceTimer;
    let lastExecuted = 0;
    
    const handleScroll = () => {
        const timeoutTime = 100;
        const now = Date.now();
        const timeSinceLastExecution = now - lastExecuted;
    
        const execute = () => {
            lastExecuted = now;
            const offsetLocation = window.innerHeight + window.scrollY + 400;
            const scrollContainer = document.querySelector('.SongSimilar');
            if (offsetLocation >= scrollContainer.scrollHeight && !isLoadingSimilar && !isLoading) {
                setPage(prev => prev + 1);
            }
        };

        clearTimeout(debounceTimer);
        if (timeSinceLastExecution > timeoutTime) execute();
        else debounceTimer = setTimeout(execute, timeoutTime - timeSinceLastExecution);
    };

    return (
        <>
        <Topbar />
        <div className="Song">
            <div>
            {isLoading || (isLoadingSimilar && page == 1) ? <SkeletonCard /> : <SongCard song={song} />}
            </div>
            <div>
                <SongSimilar isLoading={isLoadingSimilar || isLoading} songs={similarSongs} loadMore={handleScroll} setParams={setParams} />
            </div>
        </div>
        </>
    )
}

export default Song;