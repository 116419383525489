import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useTheme } from './ThemeContext';

const ThemeToggle = ({ asButton = false }) => {
    const { isDarkMode, setThemeState, themeState } = useTheme();

    if (asButton) {
        return (
            <a className="Menu-link" onClick={() => setThemeState(isDarkMode ? 'light' : 'dark')}>
                Switch to {!isDarkMode ? '🌙' : '☀️'} theme
            </a>
        );
    }

    return (
        <div className="theme-toggle" onClick={() => setThemeState(isDarkMode ? 'light' : 'dark')}>
            <Tooltip className="tooltip" anchorSelect=".reset-button-tooltip" place="left">Reset to system theme</Tooltip>
            <div className={`theme-switch ${isDarkMode ? 'dark' : ''}`}>
                <div className={`theme-switch-slider ${isDarkMode ? 'dark' : ''} unselectable`}>
                    {isDarkMode ? '🌙' : '☀️'}
                </div>
            </div>
            {themeState !== 'system' && (
                <button 
                    className="reset-button-tooltip" 
                    onClick={(e) => {
                        e.stopPropagation();
                        setThemeState('system');
                    }}
                >✖</button>
            )}
        </div>
    );
};

export default ThemeToggle;
