import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import '../styles/Topbar.css';

import Menu from './Menu';
import Searchbar from './TopbarSearchbar';
import { useSearchFocus } from './SearchFocusProvider';
import ThemeToggle from './ThemeToggle';
import TopbarLoginRegister from './TopbarLoginRegister';


function Topbar({ showSearch = true }) {
    const { isFocused } = useSearchFocus();
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const isResponsive = useMediaQuery({ query: '(max-width: 768px)' });
    

    const handleScroll = () => {
        setScrollY(window.scrollY);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menu = isResponsive ? (
        <>
            <div className={"MenuIcon" + (menuOpen ? " rotated" : "")} onClick={toggleMenu}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <Menu isOpen={menuOpen} closeMenu={closeMenu} />
        </>
    ) : (
        <>
            <TopbarLoginRegister />
            <ThemeToggle />
        </>
    );

    const classStr = `Topbar fixed-top ${isFocused && showSearch ? 'search-focused' : ''} ${scrollY > 50 ? 'Topbar-bottom-border' : ''}`;

    return (
        <div className={classStr}>
            <Link className="Topbar-logotext" to="/">Vibesim</Link>
            <div className="SearchbarContainer">
                {showSearch ? <Searchbar /> : <div className="w-100"></div>}
            </div>
            <div className="d-flex align-items-center">
                {menu}
            </div>
        </div>
    );
}

export default Topbar;
