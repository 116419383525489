import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const MixingContext = createContext();

export const useMixing = () => {
    return useContext(MixingContext);
};

export const MixingProvider = ({ children }) => {
    const [mixingList, setMixingList] = useState(() => {
        const cookie = Cookies.get('mixingList');
        return cookie ? JSON.parse(cookie) : [];
    });
    
    const [shouldMix, setShouldMix] = useState(() => {
        const cookie = Cookies.get('shouldMix');
        return cookie ? JSON.parse(cookie) : false;
    });

    const [isExpanded, setIsExpanded] = useState(Cookies.get('mixingExpanded') === 'true');

    useEffect(() => {
        Cookies.set('mixingList', JSON.stringify(mixingList));
    }, [mixingList]);

    useEffect(() => {
        Cookies.set('shouldMix', JSON.stringify(shouldMix));
    }, [shouldMix]);

    useEffect(() => {
        Cookies.set('mixingExpanded', isExpanded);
    }, [isExpanded]);
    

    const maxLength = 10;

    const addSong = (song) => {
        setMixingList(prevSongs => [...prevSongs, song]);
    };

    const removeSong = (songId) => {
        setMixingList(prevSongs => prevSongs.filter(existingSong => existingSong.id !== songId));
    };

    const value = {
        mixingList,
        addSong,
        removeSong,
        shouldMix: (shouldMix && mixingList.length > 0),
        setShouldMix,
        maxLength,
        isExpanded,
        setIsExpanded,
    };

    return (
        <MixingContext.Provider value={value}>
            {children}
        </MixingContext.Provider>
    );
};
