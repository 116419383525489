import { createContext, useState, useContext, useRef, useEffect } from 'react';
import { areWePlaying, equalSongs } from '../utils/areWePlaying';

import { useMixing } from './MixingContext';

const AudioContext = createContext();

export const useAudio = () => {
    return useContext(AudioContext);
};

export const AudioProvider = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [playingUrl, setPlayingUrl] = useState(null);
    const [playingSong, setPlayingSong] = useState(null);
    const [layoutSwitch, setLayoutSwitch] = useState(false);
    const [usingMixingList, setUsingMixingList] = useState(false);
    const [songList, setSongList] = useState([]);
    const { mixingList } = useMixing();
    const audioRef = useRef(null);

    useEffect(() => {
        if ('mediaSession' in navigator && playingSong) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: playingSong.title,
                artist: playingSong.artist,
                artwork: [
                    { src: playingSong.covers['list'], sizes: '64x64', type: 'image/png' },
                    { src: playingSong.covers['list@2x'], sizes: '640x640', type: 'image/png' }
                ]
            });
        }
        navigator.mediaSession.setActionHandler('play', () => {
            setIsPlaying(true);
            audioRef.current.play();
        });

        navigator.mediaSession.setActionHandler('pause', () => {
            setIsPlaying(false);
            audioRef.current.pause();
        });

        navigator.mediaSession.setActionHandler('nexttrack', playNextTrack);
        navigator.mediaSession.setActionHandler('previoustrack', playPreviousTrack);
        navigator.mediaSession.setActionHandler('seekto', (event) => {
            if (audioRef.current && typeof event.seekTime === 'number') {
                audioRef.current.currentTime = event.seekTime;
            }
        });
    }, [playingSong, audioRef]);

    useEffect(() => {
        const handleSongEnd = () => {
            playNextTrack();
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('ended', handleSongEnd);
        }
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('ended', handleSongEnd);
            }
        };
    }, [isPlaying, playingUrl, songList, mixingList]);

    const playNextTrack = () => {
        let srcList = usingMixingList ? mixingList : songList;
        if (usingMixingList && mixingList.length === 1) {
            srcList = songList;
            setUsingMixingList(false);
        }
        const currentIndex = srcList.findIndex(song => equalSongs(song, playingSong));
        const nextSong = srcList[(currentIndex + 1) % srcList.length];
        handlePlayPause(nextSong);
    };

    const playPreviousTrack = () => {
        const srcList = usingMixingList ? mixingList : songList;
        const currentIndex = srcList.findIndex(song => equalSongs(song, playingSong));
        const previousIndex = (currentIndex - 1 + srcList.length) % srcList.length;
        const previousSong = srcList[previousIndex];
        handlePlayPause(previousSong);
    };

    const handlePlayPause = (song) => {
        const audioElement = audioRef.current;
        const weArePlaying = isPlaying && areWePlaying(song, playingSong);
        if (audioElement) {
            if (weArePlaying) {
                audioElement.pause();
                setIsPlaying(false);
                setPlayingUrl(null);
                setPlayingSong(null);
            } else {
                if (song.preview_url !== audioElement.src) audioElement.src = song.preview_url;
                audioElement.play();
                setIsPlaying(true);
                setPlayingUrl(song.preview_url);
                setPlayingSong(song);
            }
        }
    };

    return (
    <>
        <AudioContext.Provider value={{ isPlaying, setIsPlaying, playingUrl, setPlayingUrl, playingSong, setPlayingSong, audioRef, layoutSwitch, setLayoutSwitch, handlePlayPause, songList, setSongList, usingMixingList, setUsingMixingList }}>
            {children}
        </AudioContext.Provider>
        <audio ref={audioRef} preload="none"></audio>
    </>
    );
};
