import React from 'react';
import '../styles/Song.css';
import '../styles/SkeletonCard.css';

function SkeletonCard() {
    return (
        <div className="SongCard">
            <div className="SongCardInner">
            <div className="SkeletonCover"></div>
            <div className="SongCardInfo">
                <div className="mb-4 mt-4 SkeletonText title"></div>
                <div className="mb-4 SkeletonText artist"></div>
                <div className="SkeletonButton"></div>
                <div className="flexrow spotify-add-buttons">
                    <div className="SkeletonButton rectangular"></div>
                    <div className="SkeletonButton rectangular"></div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default SkeletonCard;
