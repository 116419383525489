import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import { Tooltip } from 'react-tooltip';

import '../styles/SongSimilarSettings.css';

function SongSimilarSettings({ setParams }) {
    const [removeSameArtist, setRemoveSameArtist] = useState(() => {
        const savedRemoveSameArtist = Cookies.get('removeSameArtist');
        return savedRemoveSameArtist === 'true' || false;
    });

    useEffect(() => {
        const newParams = {
            remove_same_artist: removeSameArtist,
        };
        setParams(newParams);
    }, [removeSameArtist]);

    useEffect(() => {
        Cookies.set('removeSameArtist', removeSameArtist);
    }, [removeSameArtist]);
    

    return (
        <div className="SongSimilarSettings">
            <Tooltip className="tooltip" anchorSelect=".artists-toggle" place="top">Hide songs by original artist</Tooltip>
            <Form.Group className="Setting">
                <span>New artists: </span>
                <Form.Check
                    className="setting-switch artists-toggle"
                    type="switch"
                    label=""
                    checked={removeSameArtist}
                    onChange={e => setRemoveSameArtist(e.target.checked)}
                />
            </Form.Group>
        </div>
    );
}

export default SongSimilarSettings;
