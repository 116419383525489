import React from 'react';
import '../styles/Song.css';
import '../styles/SkeletonRow.css';

function SkeletonRow() {
return (
    <div className="SongRow SkeletonRow">
        <div className="add-button">+</div>
        <div className="SongRowBody">
            <div className="flexrow">
                {/* <div className="SkeletonRowButton"></div> */}
            <div className="SkeletonRowInfo">
                <div className="SkeletonRowText title"></div>
                <div className="SkeletonRowText artist"></div>
            </div>
            </div>
            <div className="flexrow">
                <div className={"SkeletonRowRectButton ext"}></div>
                <div className="SkeletonRowRectButton sim"></div>
            </div>
        </div>
    </div>
);
}

export default SkeletonRow;
