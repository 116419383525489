export const dummySimilar = [
    {id: 2, similarityScore: 1.0, title: 'Shape of You', artist: 'Ludwig van Beethoven'},
    {id: 3, similarityScore: 1.0, title: 'Old Town Road', artist: 'Ludwig van Beethoven'},
    {id: 4, similarityScore: 1.0, title: 'Moonlight Sonata', artist: 'Ed Sheeran'},
    {id: 5, similarityScore: 1.0, title: 'Bohemian Rhapsody', artist: 'Pharrell Williams'},
    {id: 6, similarityScore: 1.0, title: 'Imagine', artist: 'John Lennon'},
    {id: 7, similarityScore: 1.0, title: 'Halo', artist: 'Taylor Swift'},
    {id: 8, similarityScore: 1.0, title: 'Old Town Road', artist: 'John Lennon'},
    {id: 9, similarityScore: 1.0, title: 'Smooth Criminal', artist: 'The Weeknd'},
    {id: 10, similarityScore: 1.0,  title: 'Halo', artist: 'Bruno Mars'},
    {id: 11, similarityScore: 1.0,  title: 'Thinking Out Loud', artist: 'Cardi B'}
]

export const dummySong = {
    id: 1,
    covers: {
        "list@2x": 'https://placehold.co/400'
    },
    title: 'Song Title',
    artist: 'Artist Name',
    album: 'Album Name',
    duration: 123,
    similarSongs: dummySimilar
}
