import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { darkTheme, lightTheme } from '../themes';

const ThemeContext = createContext();

export const useTheme = () => {
    return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
    const [themeState, setThemeState] = useState(() => {
        const savedTheme = Cookies.get('themeState');
        return savedTheme || 'system';
    });

    const [systemDarkMode, setSystemDarkMode] = useState(
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    );

    const isDarkMode = themeState === 'system' ? systemDarkMode : themeState === 'dark';

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => setSystemDarkMode(e.matches);
        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, [themeState]);

    useEffect(() => {
        const theme = isDarkMode ? darkTheme : lightTheme;

        // Disable transitions during theme change
        const style = document.createElement('style');
        style.innerHTML = `:not(.theme-switch):not(.theme-switch-slider) { transition: none !important; }`;
        document.head.appendChild(style);

        Object.keys(theme).forEach(key => document.documentElement.style.setProperty(key, theme[key]));
        document.body.classList.remove('dark-mode', 'light-mode');
        document.body.classList.add(isDarkMode ? 'dark-mode' : 'light-mode');

        setTimeout(() => {
            document.head.removeChild(style);
        }, 100);
    }, [isDarkMode]);

    useEffect(() => {
        if (themeState !== 'system') {
            Cookies.set('themeState', themeState);
        } else {
            Cookies.remove('themeState');
        }
    }, [themeState]);

    const value = {
        isDarkMode,
        themeState,
        setThemeState
    };

    return (
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    );
};
