import { createContext, useContext } from 'react';

export const StripeContext = createContext(null);

export const useStripePromise = () => {
    const context = useContext(StripeContext);
    if (!context) {
        throw new Error("useStripePromise must be used within a StripeProvider");
    }
    return context;
};
