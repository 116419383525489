import { useState, useEffect, useRef } from 'react';
import { InputGroup, Form, Spinner } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TopbarSearchbarResults from './TopbarSearchbarResults';
import { useSearchFocus } from './SearchFocusProvider';

const Searchbar = () => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { isFocused, setIsFocused } = useSearchFocus();
    const [mouseDownOnResults, setMouseDownOnResults] = useState(false);
    const debounceTimeoutRef = useRef(null);
    const searchResultsRef = useRef(null);

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setIsFocused(false);
        }
    };

    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            if (searchValue.trim() !== '') {
                setIsLoading(true);

                fetch(`/api/search?q=${searchValue}`)
                    .then(response => response.json())
                    .then(data => {
                        setSearchResults(data);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error('Error fetching search results:', error);
                        setIsLoading(false);
                    });
            } else {
                setSearchResults([]);
            }
        }, 300);

        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, [searchValue]);
    
    // Add event listener to detect clicks inside the search results
    // This is needed to delegate the blur to the results list, to ensure navigation executes correctly
    useEffect(() => {
        // Check if the target is either the search input or contained in search results
        const isValidTarget = (target, ref) => target.className?.includes?.('search-input') || ref.current?.contains(target);

        const handleMouseDown = (event) => {
            setIsFocused(isValidTarget(event.target, searchResultsRef));
            setMouseDownOnResults(isValidTarget(event.target, searchResultsRef));
        };

        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    return (
        <div className={"w-100 position-relative d-flex align-items-center justify-content-center"}>
            <div className="TopbarSearchbar position-relative">
            <InputGroup className={`custom-search-input ${isFocused && searchResults.length > 0 ? 'results-shown' : 'results-hidden'}`}>
                <InputGroup.Text className="dark-input search-icon">
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                    className="dark-input search-input"
                    type="text"
                    placeholder="Search for a song..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                        if (!mouseDownOnResults) setIsFocused(false);
                        setMouseDownOnResults(false);
                    }}
                />
            </InputGroup>
                {isLoading && (
                    <div className="mt-2 position-absolute text-center search-spinner">
                        <Spinner animation="border" size="sm" />
                    </div>
                )}
                {isFocused && searchResults.length > 0 && searchValue.trim() !== '' && (
                    <TopbarSearchbarResults searchResults={searchResults} setIsFocused={setIsFocused} ref={searchResultsRef} />
                )}
            </div>
        </div>
    );
};

export default Searchbar;
