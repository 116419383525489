import React, { forwardRef } from "react";
import { useMixing } from './MixingContext';
import { Tooltip } from 'react-tooltip';
import { useSearchFocus } from './SearchFocusProvider';
import { useNavigate } from "react-router-dom";


const TopbarSearchbarResultsRow = forwardRef(({ result, setPlaceholderOnError }, ref) => {
    const { mixingList, addSong, removeSong, maxLength } = useMixing();
    const isSongInList = mixingList.some(existingSong => existingSong.id === result.id);
    const maxLengthReached = mixingList.length >= maxLength;
    const tooltipContent = isSongInList ? "Remove from playlist" : "Add to playlist";
    const { setIsFocused } = useSearchFocus();

    let songAdded = false;

    const navigate = useNavigate();

    const handleAddSongClick = (e) => {
        songAdded = true;
        if (maxLengthReached && !isSongInList) return;
        if (isSongInList) {
            removeSong(result.id);
        } else {
            addSong(result);
        }
    };

    const resultClick = (result) => {
        if (songAdded) {
            songAdded = false;
            return;
        }
        setIsFocused(false);
        navigate(`/song/${result.id}`);
    }


    return (
        <div ref={ref} className="SearchbarResult" onClick={() => resultClick(result)}>
            <Tooltip className="tooltip" id={`tooltip-add-button`} />
            <div 
                className="add-button" 
                onClick={handleAddSongClick} 
                data-tooltip-id={`tooltip-add-button`}
                data-tooltip-content={tooltipContent}
                data-tooltip-place="top"
                style={maxLengthReached && !isSongInList ? { pointerEvents: 'none' } : {}}
            >
                {isSongInList ? '-' : '+'}
            </div>
            <img src={result.covers["list"] || ""} alt={result.title} width="40" className="mr-2" onError={setPlaceholderOnError} />
            <span className="truncate-result">
                <span className="artist-result">{result.artist}</span>&nbsp;- {result.title}
            </span>
        </div>
    );
});

export default TopbarSearchbarResultsRow;
