export const darkTheme = {
    '--primary': '#232323',
    '--primary-highlight': '#333333',
    '--secondary': '#2F2F2F',
    '--secondary-highlight': '#3F3F3F',
    '--text-color': '#CCCCCC',
    '--text-color-highlight': '#FFFFFF',
    '--text-color-weak': '#AAAAAA',
    '--shadow-extra-weak': '#FFFFFF22',
    '--shadow-weak': '#FFFFFF44',
    '--shadow-medium': '#FFFFFF66',
    '--soft-gradient-end': '#121212',
    '--soft-gradient-start': '#2F2F2F',
    '--soft-gradient-hover-start': 'rgba(55, 55, 55, 0.9)',
    '--soft-gradient-hover-end': 'rgba(15, 15, 15, 0.9)',
    '--warning': 'rgb(220, 53, 69)',
    '--success': 'rgb(25, 135, 54)',
};
  
export const lightTheme = {
    '--primary': '#FFFFFF',
    '--primary-highlight': '#F5F5F5',
    '--secondary': '#F0F0F0',
    '--secondary-highlight': '#E0E0E0',
    '--text-color': '#333333',
    '--text-color-highlight': '#000000',
    '--text-color-weak': '#666666',
    '--shadow-extra-weak': '#00000022',
    '--shadow-weak': '#00000044',
    '--shadow-medium': '#00000066',
    '--soft-gradient-start': '#FEFEFE',
    '--soft-gradient-end': '#F0F0F0',
    '--soft-gradient-hover-start': 'rgba(215, 215, 215, 0.9)',
    '--soft-gradient-hover-end': 'rgba(255, 255, 255, 0.9)',
    '--warning': 'rgb(220, 53, 69)',
    '--success': 'rgb(25, 135, 54)',
};
