export const equalUrls = (first, second) => {
    if (!first || !second) return false;
    const normalize = (url) => url.startsWith('//') ? 'https:' + url : url;
    const url1 = new URL(normalize(first));
    const url2 = new URL(normalize(second));

    return url1.host === url2.host && url1.pathname === url2.pathname;
};

export const equalSongs = (first, second) => {
    if (!first || !second) return false;
    return first.id === second.id && equalUrls(first.preview_url, second.preview_url);
};

export const areWePlaying = (song, playingSong) => {
    return equalSongs(song, playingSong);
}
