import { useLocation, Link } from 'react-router-dom';

import { useAuth } from './AuthContext';

const TopbarLoginRegister = () => {
    const { isLoggedIn } = useAuth();
    const location = useLocation();

    const searchbarClass = location.pathname.includes("song") ? "hidden" : "";
    const divClass = "TopbarLoginRegister " + searchbarClass;

    if (isLoggedIn) {
        return  (
            <div className={divClass}>
                <Link to="/user" className="login-link">Account</Link>
            </div>
        );
    }

    return (
        <div className={divClass}>
            <Link to="/login" className="login-link">Log In</Link>
            <Link to="/register" className="register-link">Register</Link>
        </div>
    );
}

export default TopbarLoginRegister;