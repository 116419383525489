import React, { useState, useEffect } from 'react';
import '../styles/Notification.css';

const Notification = ({ message, type, closeNotification }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (message) {
            setShow(true);
            const hideTimer = setTimeout(() => setShow(false), 3000);
            const closeTimer = setTimeout(() => closeNotification(), 3300);

            return () => {
                clearTimeout(hideTimer);
                clearTimeout(closeTimer);
            };
        }
    }, [message, closeNotification]);

    return (
        <div className={`notification ${type} ${show ? 'show' : ''}`}>
            {message}
        </div>
    );
};


export default Notification;
