import React from 'react';
import '../styles/Equalizer.css';

const Equalizer = () => {
    return (
        <div className="equalizer flexrow">
            <div className="equalizer-bar" style={{ animationDelay: `${Math.random() * 0.3}s` }}></div>
            <div className="equalizer-bar" style={{ animationDelay: `${Math.random() * 0.3}s` }}></div>
            <div className="equalizer-bar" style={{ animationDelay: `${Math.random() * 0.3}s` }}></div>
        </div>
    );
};

export default Equalizer;