import SongCover from "./SongCover";
import PlayPauseButton from "./PlayPauseButton";
import { useAudio } from "./AudioProvider";
import { areWePlaying } from "../utils/areWePlaying";
import SpotifyButton from "./SpotifyButton";
import { useMixing } from "./MixingContext";

import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';



function SongCard({ song }) {
    const { playingSong, isPlaying } = useAudio();
    const { mixingList, addSong, removeSong } = useMixing();
    const weArePlaying = isPlaying && song && playingSong && areWePlaying(song, playingSong);
    
    const isInMixingList = mixingList.some(existingSong => existingSong.id === song.id);

    const handleMixingToggle = () => {
        if (isInMixingList) {
            removeSong(song.id);
        } else {
            addSong(song);
        }
    };

    return (
        <div className={`SongCard${weArePlaying ? ' playing' : ''}`}>
            <div className="SongCardInner">
                <Tooltip className="tooltip" anchorSelect=".songcard-add-button" place="top">{isInMixingList ? 'Remove from playlist' : 'Add to playlist'}</Tooltip>
                <SongCover cover={song.covers["list@2x"]} isPlaying={weArePlaying} />
                <div className="SongCardInfo">
                    <h2 className="mt-3 text-center truncate-card">{song.title}</h2>
                    <h4 className="mb-3 text-center truncate-card">{song.artist}</h4>
                    <div className="mt-2">
                        <PlayPauseButton key={song.preview_url} song={song} size="3x" loadFirst={true} />
                    </div>
                    <div className="flexrow spotify-add-buttons">
                        <SpotifyButton song={song} />
                        <button className="songcard-add-button similar-button" onClick={handleMixingToggle}>
                            <FontAwesomeIcon icon={isInMixingList ? faMinus : faPlus} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default SongCard;